import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
    const slides = document.querySelectorAll('.splide:not(.product-stocks_splide):not(#main-carousel):not(#thumbnail-carousel):not(.process_splide)');

    slides.forEach(el => {

        let splide = new Splide(el, {
            arrows: true,
            pagination: true,
            type: 'loop',
            autoplay: '3000',
            autoWidth: el.dataset.autoWidth,
            gap: el.dataset.gap,
            breakpoints: {
                768: {
                    arrows: false
                }
            }
        });
        splide.mount();
    });
});
